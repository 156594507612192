import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';

import {StripeProvider} from 'react-stripe-elements';
import {Stripe} from 'stripe.js'
import Checkout from '../../components/checkout';

import Ross from '../../pics/students/ross_sq.jpg'

const RossStory = () => (


	<div className="white grid-1-3"
	>
	<Helmet

	title="Student's story: Ross Fleming, from a beginner to a developer in Madrid"
	meta={[
		{ name: 'description', content: 'Students story: Ross Fleming, from a beginner to a developer in Madrid' },
		{ name: 'keywords', content: 'Students story, Barcelona Code School' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]}
		/>
		<div>
		<h1>Student's story: Ross Fleming</h1>
		<h2>Graduated from the JavaScript Full-Stack Bootcamp</h2>
		<h2>Job landed: front-end developer at Raet, Madrid</h2>
		</div>
		<div style={{
			marginTop: '2em'
		}}>

		<img style={{
			float: 'left',
			width: '50%',
			marginRight: '1em',
		}}
		alt="Ross got hired by RAET after finishing javascript full-stack bootcamp"
		src={Ross} />
		
		<p>After working as an electrical designer for more than ten years, Ross took a life-changing decision – moved to Barcelona to switch jobs and make a transition into tech.</p>
		<p>As most Barcelona Code School’s students Ross was a total newbie at programming. Other than getting his feet wet with the pre-course he came with no previous programming experience.</p>
		<p>Ross found it extremely helpful that the school’s approach allowed a beginner like him dive deep into JavaScript from the day one and he is glad there is a school that does not ask you to have a certain level of knowledge before you start.</p>
		<p><blockquote>“It was an entirely new industry for me, I had never done a bootcamp before so I was unsure exactly what to expect”.</blockquote></p>
		<p>Ross was one of those students focusing on nothing but the bootcamp as he didn’t have a part-time job nor business to run as some of his classmates. Even though bootcamp was an entirely new learning mode for him, he says that he liked immersive learning experience as it allowed him to focus and make progress fast. And indeed, a 9-week JavaScript Full-Stack Bootcamp might seem as one-continuous-day-of-code as the course takes at least 60 hours of your time a week. The good thing is that right after the course starts, your brain gets wired with the code and you become extremely dedicated to the subject ignoring all the possible distractions.</p>
		<p><blockquote>“Nothing but code is on your mind during the whole bootcamp period, it is a perfect environment for learning as there are lectures in the morning and later your have instructors at your disposal so you have no other option but to stick to the routine”, explains Ross.</blockquote></p>
		<p><blockquote>“If you put a 100% percent of yourself, you get the same in return and can start looking for your new job as a developer. It took me a bit more than one month to find a job, it is incredible. To those who have never had experience of intensive training, bootcamps can seem to be some kind of wizard schools”.</blockquote></p>
		<p><blockquote>“There were a lot of content to cover during the 9 weeks and the projects were challenging. Bootcamp had an intense and a big learning curve. I’ve found it challenging but rewarding, working on the projects has been a great way to develop the skills and find the area I enjoy the most.” Despite those challenges Ross says that “being determined and knowing that the hard work will secure me a job, helped me to go through the course.”</blockquote></p>
		<p>Ross continues explaining that there were moments when he used to get annoyed with himself when he couldn’t get the answer right away. If he had the opportunity to go through a bootcamp again, he would take his time and logically think about each question, exercise or step he was going to make. Sometimes even brightest students might get frustrated during the course and to avoid this Ross advises to take a break on school’s terrace, chat to your classmates or simply go for a walk around the block, rewind, and then clear your doubts with the instructor or teaching assistant.</p>
		<p>Talking about advice. If you are about to choose a bootcamp Ross suggests you to check a school’s syllabus and ask about benefits of the current curriculum. Doing a research might seem obvious but there are some students who are completely unaware of the technology stack they are about to learn. So do your research, that has a twofold benefit. First, it will help you to find out if the course materials are in line with what you want to learn. Second, if the school says that the curriculum stays the same for ages it can mean that it’s not really up to date with the fast-changing tech scene. Ross did his research before starting the bootcamp therefore he was happy with the outcome.</p>
		<p>The last advice Ross has for the future students is that they must be prepared to work hard and don’t underestimate the course.</p>
		<p><blockquote>“If you work hard you will enjoy the experience and be amazed at what can you learn. I didn’t stop learning even after finishing the bootcamp. I am well aware that in order to become first class web developer I need to practise the concepts I have learnt over those 9 weeks and I am lucky that due to the high demand of developers in the job market everyone has the opportunity to learn on the job.”</blockquote></p>
		<p>Ross has had a truly successful outcome landing a job with Raet in Madrid as a junior front-end developer. He is joining a team of a hundred devs to learn from and grow as a professional. So once again, hard work pays off!</p>

		</div>
		</div>


		)

export default RossStory



